import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M17.6572 14.8287L16.243 13.4145L17.6572 12.0003C19.2193 10.4382 19.2193 7.90555 17.6572 6.34345C16.0951 4.78136 13.5624 4.78136 12.0003 6.34345L10.5861 7.75767L9.17188 6.34345L10.5861 4.92924C12.9292 2.58609 16.7282 2.58609 19.0714 4.92924C21.4145 7.27239 21.4145 11.0714 19.0714 13.4145L17.6572 14.8287ZM14.8287 17.6572L13.4145 19.0714C11.0714 21.4145 7.27238 21.4145 4.92924 19.0714C2.58609 16.7282 2.58609 12.9292 4.92924 10.5861L6.34345 9.17188L7.75766 10.5861L6.34345 12.0003C4.78135 13.5624 4.78135 16.0951 6.34345 17.6572C7.90555 19.2192 10.4382 19.2192 12.0003 17.6572L13.4145 16.2429L14.8287 17.6572ZM14.8287 7.75767L16.243 9.17188L9.17188 16.2429L7.75766 14.8287L14.8287 7.75767Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }